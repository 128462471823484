import { render, staticRenderFns } from "./editProject.vue?vue&type=template&id=2f0e55d7&scoped=true"
import script from "./editProject.vue?vue&type=script&lang=js"
export * from "./editProject.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f0e55d7",
  null
  
)

export default component.exports