<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <ve-table
          ref="tableRef"
          id="task_change_log_table"
          fixed-header
          max-height="calc(100vh - 800px)"
          :columns="columns"
          :table-data="taskChangeLogs"
          :border-around="true"
          :border-x="true"
          :border-y="true"
          :sort-option="sortOption"
          :cell-style-option="cellStyleOption"
          :scroll-width="500"
        />
        <div v-show="taskChangeLogs.length === 0" class="empty-table">
          Data Empty.
        </div>
        <div class="table-pagination mt-2 text-right">
          <ve-pagination
            :total="taskChangeLogs.length"
            :page-index="pageIndex"
            :page-size="pageSize"
            :page-size-option="pageOptions"
            @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "TaskChangeLogTable",
  props: ["taskChangeLogs"],
  data: function() {
    return {
      filterable_col_list: [],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          let className = "";
          if (this.filterable_col_list.includes(column.field)) {
            className += "filterable-column " + column.field;
          }
          if (rowIndex === 0) {
            className += " table-header-cell-class";
          }
          return className;
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {},
      showEmpty: false,

      requiredRules: [v => !!v || "Required"],

      taskChangeLogData: null,
      totalChangeLogs: 0
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    taskChangeLogs: function(newVal, oldVal) {
      this.taskChangeLogData = newVal;
      this.totalChangeLogs = newVal.length;
      if (this.totalChangeLogs) this.showEmpty = false;
      else this.showEmpty = true;
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"]),
    columns() {
      return [
        {
          key: "date",
          title: "Fecha",
          field: "date",
          align: "left",
          width: 90,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.date}</span>;
          }
        },
        {
          key: "userId",
          title: "Usuario",
          field: "userId",
          align: "left",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>
                {row?.user && row.user.name} {row?.user && row.user.surname}
              </span>
            );
          }
        },
        {
          key: "columnName",
          title: "Campo Cambio",
          field: "columnName",
          align: "left",
          width: 120,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.columnName}</span>;
          }
        },
        {
          key: "beforeVal",
          title: "Antes",
          field: "beforeVal",
          align: "left",
          width: 90,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.beforeValue}</span>;
          }
        },
        {
          key: "afterVal",
          title: "Después",
          field: "afterVal",
          align: "left",
          width: 90,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.afterValue}</span>;
          }
        }
      ];
    }
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("task_change_log_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        responsibleId: _.get(item, "responsibleId"),
        brandId: _.get(item, "brandId"),
        docFile: _.get(item, "docFile"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        static: {
          file: null,
          static: _.get(item, "static")
        }
      };
      return model;
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    closeTaskDetail() {
      this.showEditRecurring = false;
      this.$nextTick(() => {
        this.bodyData = null;
      });
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        `#task_change_log_table .filterable-column`
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = `projects/gantt/taskChangeLog/${this.project}`;
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.tasks = resp.data;
      this.totaltasks = resp.total;
      if (this.tasks.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {}
};
</script>

<style>
.empty-table {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding-top: 20px;
  padding-bottom: 35px;
}
.table-header-cell-class {
  background: #065c83 !important;
  color: #fff !important;
}
</style>
