<template>

  <div>

    <edit-task
        v-model="showEditTask"
        :opts="editTaskOpts"
        @onChange="onTaskEditChanged"
    ></edit-task>

    <v-dialog v-if="project" scrollable v-model="showEdit" >
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container class="m-0">
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      v-model="project.name"
                      v-bind:label="`Nombre Proyecto`"
                      required
                      :rules="requiredRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="m-0 p-0">
                <v-col class="m-0 p-0" cols="12" sm="12" md="6">
                  <v-textarea
                      v-model="project.description"
                      clear-icon="mdi-close-circle"
                      label="Descripción"
                      row-height="15"
                      rows="2"
                  ></v-textarea>
                </v-col>
                <v-col  v-if="project?.static" cols="12" sm="12" md="6" class="text-center">
                  <v-file-input
                      show-size
                      v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                      @change="onChangePDFFile"
                      v-model="project.static.file"
                      accept=".pdf"
                      required
                      :rules="[fileRules]"
                  >
                    <template v-if="project.static.static" #label>
                      {{ project.static.static.file }}
                    </template>
                  </v-file-input>
                  <v-btn
                      v-if="project.static.static"
                      color="blue darken-1"
                      class="mb-2"
                      @click="downloadPDF(project.static.static)"
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                    {{ `${$t("Download")} ${$t("PDF File")}` }}
                  </v-btn>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                      v-model="project.responsibleId"
                      v-bind:label="`Responsable`"
                      item-text="name"
                      item-value="id"
                      :items="user_list"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-autocomplete
                      v-model="project.brandId"
                      v-bind:label="`Marca`"
                      item-text="name"
                      item-value="id"
                      :items="brand_list"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                      ref="startDate_menu"
                      v-model="select_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="project.startDate"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="project.startDate"
                        no-title
                        @input="select_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <v-menu
                      ref="endDate_menu"
                      v-model="select_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="project.endDate"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="project.endDate"
                        no-title
                        scrollable
                        @input="select_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                        required
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                      v-model="project.templateType"
                      v-bind:label="`Tipo de Plantilla`"
                      :items="TEMPLATE_TYPES"
                      item-text="text"
                      item-value="value"
                      clearable
                      required
                      :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <IndividualProjectTable
              v-if="!disableTasks"
              :pageSize="10"
              :title="`Tareas del proyecto`"
              :projectId="projectId"
              :tableName="`globalProjectTasks`"
              :showAllTasks="true"
          ></IndividualProjectTable>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn v-if="!disableTasks" color="primary" @click="addTask">
              Añadir tarea
            </v-btn>

            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
                color="success"
                @click="onSaveClick(project)"
                :loading="isSaving"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>


  </div>

</template>

<script>
import ApiService from "@/services/apiService";
import {
  TEMPLATE_TYPES,
} from "./common";
import {logError, logInfo, logWarning} from "../../../utils";
import _ from "lodash";
import {mapGetters} from "vuex";
import download from "js-file-download";
import IndividualProjectTable from "../individualproject/IndividualProjectTable.vue";
export default {
  name: "editProject",

  props: {
    value: { type: Boolean, default: false },
    opts: { type: Object },
    disableTasks: { type: Boolean, default: false }
  },

  components: {
    IndividualProjectTable,
    editTask,
  },

  data: function() {
    return {
      project: null,
      valid: true,
      user_list: [],
      brand_list: [],

      showEditTask: false,
      isSaving: false,

      editTaskOpts: {},
      isTableReloading: false,

      select_startDate_menu: false,
      select_endDate_menu: false,

      TEMPLATE_TYPES,

      requiredRules: [v => !!v || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },


    }
  },

  watch: {
    async opts(val) {
      await this.initializeModel(val)
    }
  },


  computed:{
    ...mapGetters("auth", ["user"]),
    isNewProject() {
      return !this.project || !this.project.id
    },

    formTitle() {
      return this.isNewProject ? "Nuevo proyecto" : "Editar proyecto";
    },
    projectId() {
      return this.isTableReloading ? null : this.project?.id
    },
    showEdit: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

  },

  methods: {

    async init() {
      const { data } = await ApiService.get('tasks/opts');
      this.brand_list = data.brand_list
      this.user_list = data.user_list
    },

    async onTaskEditChanged(){
      await this.reloadTable()
    },


    addTask() {
      if(this.isNewProject) {
        logWarning("Tienes que guardar el proyecto antes de poder añadir tareas")
        return
      }
      this.editTaskOpts = {
        projectId: this.project.id
      }
      this.showEditTask = true
    },


    async onChangePDFFile(file) {
      this.project.static.file = file;
    },

    async downloadPDF(document) {
      this.show();
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },


    async onSaveClick(item) {

      const showEdit = this.isNewProject // do not close edit dialog on save for new projects because user may need to add tasks to project

      if (this.$refs.form.validate()) {
        if (item.startDate && item.endDate) {
          if (item.startDate > item.endDate) {
            logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
            return;
          }
        }
        let body = _.omit(item, ["static"]);
        try {
          this.isSaving = true;
          let file = item.static.file;
          if (file) {
            let { data } = await ApiService.uploadFile(file);
            body.docFile = data.id;
          }
          let resp = null
          if (body.id) {
            resp = await ApiService.put(`projects/${body.id}`, body);
          } else {
            resp = await ApiService.post(`projects`, body);
          }
          if (resp){ //merging model with resp
            this.project = {
              ...this.project,
              ...resp.data
            }
            console.log(this.project)
          }
          logInfo("proyectos actualizada");
          this.showEdit = showEdit;
          this.isSaving = false;
          this.$emit('onChange', this.project)
        } catch (error) {
          this.isSaving = false;
          this.showEdit = showEdit;
          logError(error);
        }
      }
    },

    closeEditDialog() {
      this.showEdit = false;
    },

    createModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        templateType: _.get(item, "templateType"),
        responsibleId: _.get(item, "responsibleId"),
        brandId: _.get(item, "brandId"),
        docFile: _.get(item, "docFile"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        static: {
          file: null,
          static: _.get(item, "static")
        }
      };
      if (!model.id) model["responsibleId"] = this.user.id;
      return model;
    },

    async initializeModel(opts) {
      const model = this.createModel(opts)
      this.project = model;
      this.showEdit = true;
    },

    async reloadTable() {
      this.isTableReloading = true
      this.$nextTick(() => {
        this.isTableReloading = false
      });

    }


  },

  async mounted() {
    await this.init();
  },

}


import editTask from "./editTask";
</script>

<style scoped>
</style>