<template>
  <div id="taskDetailDialog">

    <edit-task
        v-model="showEdit"
        :opts="editOpts"
        @onChange="onTaskChanged"
    ></edit-task>

    <v-dialog
      scrollable
      v-model="showCommentDialog"
      max-width="500px"
      v-if="selectedComment"
    >
      <v-form ref="form_task_detail" v-model="validEdit" lazy-validation>
        <v-card>
          <v-card-title>Nuevo Comentario</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <v-textarea
                  label="Comentario"
                  v-model="selectedComment.comment"
                  rows="3"
                  required
                  :rules="requiredRules"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="text-center">
                <v-file-input
                  show-size
                  v-bind:label="`${$t('Upload')} ${$t('PDF File')}`"
                  @change="onChangePDFFile"
                  v-model="selectedComment.static.file"
                  accept=".pdf"
                  required
                  :rules="[fileRules]"
                >
                  <template v-if="selectedComment.static.static" #label>
                    {{ selectedComment.static.static.file }}
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeCommentDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isLoadingComment"
              @click="saveComment(selectedComment)"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      scrollable
      v-model="showTaskDetailDialog"
      content-class="dialog-task-detail"
      v-if="selectedTask"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
              class="d-flex flex-column justify-end"
            >
              <span class="text-h4">{{ selectedTask.name }}</span>
              <span class="text-subtitle-1"
                >{{ selectedTask.brand?.name }} /
                {{ selectedTask.project?.name }}</span
              >
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
              lg="6"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column">
                  <div>
                    <span class="my-prop-label">F. Fin:</span><span class="text-subtitle-2">{{ selectedTask.task_endDate }}</span>
                  </div>
                  <div>
                    <span class="my-prop-label">Prio.:</span><span class="text-subtitle-2">{{ selectedTask.priority }}</span>
                  </div>
                  <div>
                    <span class="my-prop-label">Resp.:</span><span class="text-subtitle-2">{{ selectedTask.tasksResponsible?.name }}</span>
                  </div>


                </div>
                <task-status class="pt-2" :task="selectedTask"></task-status>

              </div>
            </v-col>
          </v-row>
          <v-divider
              style="background-color: black !important; border-color: black !important;"
            :thickness="5"
            class="border-opacity-100 primary"
          ></v-divider>
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <span>Fecha Inicio: {{ selectedTask.task_startDate }}</span>
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <span
                >Fecha asignación:
                {{
                  selectedTask.assignmentDate &&
                    selectedTask.assignmentDate.slice(0, 10)
                }}</span
              >
            </v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <span
                >Asignado Por: {{ selectedTask.tasksResponsible?.name }}
                {{ selectedTask.tasksResponsible?.surname }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center p-0">
              <v-textarea
                label="Descripción"
                row-height="15"
                rows="2"
                outlined
                readonly
                disabled
                :value="selectedTask.description"
              ></v-textarea>
              <div
                class="absolute"
                style="position: absolute; bottom: 35px; right: 15px;"
                v-if="selectedTask.static"
              >
                <v-btn
                  text
                  color="info"
                  @click="downloadPDF(selectedTask.static)"
                  >Descargar</v-btn
                >
              </div>
            </v-col>
          </v-row>
          <v-container :fluid="containerFluid">
            <v-row class="align-end">
              <v-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
                class="text-left text-subtitle-1"
                >Comentarios</v-col
              >
              <v-col cols="12" sm="6" md="6" lg="6" class="text-right">
                <v-btn color="info" @click="openCommentDialog">+ añadir</v-btn>
              </v-col>
            </v-row>
            <v-row class="overflow-y-auto user-comment scroll-container" style="direction: ltr">
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                v-if="!selectedTask.task_comments.length"
                class="text-center"
              >
                <span class="text-subtitle-2">Sin comentarios</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                v-for="(el, index) in selectedTask.task_comments"
                :key="index"
                class="border-lg rounded-lg individual-comment scroll-content"
              >
                <div class="user-comment-box">
                  <span class="text-subtitle-2 absolute">
                    {{ el.date }} / {{ el.user?.name }} {{ el.user?.surname }}
                  </span>
                  <br />
                  <span style="white-space: pre-wrap;">{{ el.comment }}</span>
                </div>
                <div
                  class="absolute"
                  style="position: absolute; bottom: 0; right: 10px;"
                >
                  <v-btn
                    text
                    color="teal"
                    v-if="el.static"
                    @click="downloadPDF(el.static)"
                    >Descargar</v-btn
                  >
                  <v-btn
                    text
                    color="error"
                    @click="deleteComment(el)"
                    v-if="user.id === el.userId"
                    >Borrar</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12">
              <TaskChangeLogTable
                :taskChangeLogs="selectedTask?.task_change_logs"
              ></TaskChangeLogTable>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onEditClick">
            {{ $t("edititem") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeTaskDetail">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import { mapGetters } from "vuex";
import download from "js-file-download";
import TaskChangeLogTable from "./TaskChangeLogTable.vue";
import taskStatus from "../common/taskStatus";
import editTask from "../common/editTask";

export default {
  name: "TaskDetailDialog",
  props: ["showTaskDetail", "selectedTaskId"],
  components: {
    TaskChangeLogTable,
    taskStatus,
    editTask
  },
  data: function() {
    return {
      loadingInstance: null,
      taskId: null,
      selectedTask: null,

      showEdit: false,
      editOpts: null,

      /********************* Task Detail ****************/

      hasChanged: false, //to track edition

      requiredRules: [v => !!v || "Required"],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      fileRules: file => {
        if (file) {
          if (file.size <= 5e6) {
            return true;
          } else {
            return "File size should be less than 1 MB!";
          }
        }
        return true;
      },
      showTaskDetailDialog: false,
      containerFluid: true,
      showCommentDialog: false,
      selectedComment: null,
      isLoadingComment: false,
      valid: true,
      validEdit: true
    };
  },
  watch: {
    showTaskDetail: function(newVal, oldVal) {
      this.hasChanged = false
      this.showTaskDetailDialog = newVal;
    },
    showTaskDetailDialog: function(newVal, oldVal) {
      if (!newVal) this.$emit("closeTask",this.hasChanged);
    },
    selectedTaskId: function(newVal, oldVal) {
      this.taskId = newVal;
      if (newVal) this.getDataFromApi();
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isBrand"])
  },
  methods: {
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector(`#taskDetailDialog`),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    closeTaskDetail() {
      this.showTaskDetailDialog = false;
      this.$nextTick(() => {});
    },

    async onEditClick() {
      this.editOpts = this.selectedTask
      this.showEdit = true
    },

    openCommentDialog() {
      this.selectedComment = {
        comment: null,
        static: {
          static: null,
          file: null
        }
      };
      this.showCommentDialog = true;
    },

    closeCommentDialog() {
      this.showCommentDialog = false;
      this.selectedComment = null;
    },

    async deleteComment(comment) {
      if (
        window.confirm("¿Estás seguro de que quieres eliminar este comentario?")
      ) {
        try {
          this.show();
          let fetch_url = `projects/comment/${comment.id}/${this.user.id}`;
          let data = await ApiService.delete(fetch_url);
          this.close();
          if (data.success) {
            await this.getDataFromApi();
            logInfo("¡Eliminado con éxito!");
          } else {
            logError(data.message);
          }
        } catch (error) {
          this.close();
          logError(error);
        }
      }
    },

    async onChangePDFFile(file) {
      this.selectedComment.static.file = file;
    },

    async downloadPDF(document) {
      this.show();
      await new Promise(resolve => setTimeout(resolve, 2000));
      let file_uri = ApiService.getFileUri(document ? document : null);
      if (file_uri !== null) {
        console.log(file_uri);
        try {
          const response = await ApiService.get(file_uri, {
            responseType: "arraybuffer"
          });
          download(response, document.file);
          this.close();
        } catch (error) {
          this.close();
        }
      }
    },

    async getDataFromApi() {
      this.show();
      let fetch_url = `projects/taskdetail/${this.taskId}`;
      try {
        const resp = await ApiService.get(fetch_url);
        this.selectedTask = resp.data;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    async onTaskChanged(task) {
      this.hasChanged = true
      await this.getDataFromApi();
    },


    async saveComment(item) {
      if (this.$refs.form_task_detail.validate()) {
        try {
          let body = _.omit(item, ["static"]);
          let file = item.static.file;
          if (file) {
            let { data } = await ApiService.uploadFile(file);
            body.docFile = data.id;
          }

          this.isLoadingComment = true;
          let local_date = moment(new Date())
            .local()
            .format("YYYY-MM-DD");
          body = {
            ...body,
            userId: this.user.id,
            taskId: this.selectedTaskId,
            date: local_date
          };
          let fetch_url = `projects/addComment`;
          let data = await ApiService.post(fetch_url, body);
          if (data.success) {
            logInfo("comentario agregado");
            this.getDataFromApi();
          } else {
            logError(data.message);
          }
          this.showCommentDialog = false;
          this.isLoadingComment = false;
        } catch (error) {
          this.showCommentDialog = false;
          this.isLoadingComment = false;
          logError(error);
        }
      }
    },
    async init() {}
  },
  async mounted() {}
};
</script>
<style>
.dialog-task-detail {
  max-width: 800px;
  max-height: 850px;
  border: 2px solid #184e65eb;
  border-radius: 11% !important;
}
.dialog-task-detail .v-card {
  padding: 10px !important;
}
.dialog-task-detail .user-comment {
  max-height: 175px;
  margin-bottom: 10px;
}
.dialog-task-detail .user-comment .individual-comment {
  border: 1px solid #092a386e;
  margin-bottom: 3px !important;
  height: 85px;
  padding: 8px !important;
}
.user-comment-box {
  overflow-y: auto !important;
  max-height: 92% !important;
  padding-right: 2px;
}
.user-comment-box::-webkit-scrollbar {
  width: 3px;
}
.user-comment-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.user-comment-box::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

.scroll-container {
  padding-left: 3px;
  direction: rtl; /* Change the content direction to right-to-left */
}
.scroll-content {
  direction: ltr; /* Keep the content direction left-to-right */
}
/* Optional: Customize the scrollbar appearance */
.scroll-container::-webkit-scrollbar {
  width: 9px;
}
.scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scroll-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.my-prop-label {
  margin-bottom: 2px;
  text-align: center;
  font-weight: bold;
  display: inline-block;
  width: 50px;
  margin-right: 3px;
  border: 1px solid;
  background: #e3e3e3;
}
</style>
